<template>
  <v-col
    cols="12"
    xs="12"
    sm="6"
    md="6"
    xl="3"
    class="align-stretch d-flex responsive-card"
  >
    <v-card width="100%" elevation="4">
      <v-card-title class="px-6 pb-0 h6 mb-4 d-flex justify-space-between">
        <div class="">
          <span class="h6 font-weight-bold">{{ $t('heading.instance.siteDetails.loadingSpeed.title') }}</span>
        </div>
        <a
          v-if="$store.state.home.app.page_speed_enabled"
          :href="`https://pagespeed.web.dev/report?url=${encodeURIComponent(
            instance.url
          )}`"
          target="_blank"
          class="full-report-link font-weight-600 p-2 d-flex align-center"
        >
          <v-icon size="16"> $externallink</v-icon>

          <span class="ml-1">{{ $t('heading.instance.siteDetails.loadingSpeed.link') }}</span>
        </a>
      </v-card-title>

      <v-card-text>
        <v-row>
          <template
            v-if="isSpeedDefined || $store.state.home.app.page_speed_enabled"
          >
            <v-col cols="6" class="mt-4 pt-2 pb-0">
              <div class="d-flex align-center flex-column">
                <progress-speed-chart
                  :value="isSpeedDefined ? speedDesktop : '-'"
                  :color="
                    speedMobile == '?'
                      ? '#E5E5F0'
                      : speedDesktop >= 90
                      ? 'success'
                      : speedDesktop >= 50
                      ? 'warning'
                      : 'error'
                  "
                ></progress-speed-chart>
                <p
                  v-if="isSpeedDefined"
                  class="p-2 text-center mt-3 mb-0 black--text"
                >
                  {{ $t('heading.instance.siteDetails.loadingSpeed.desktop') }}
                </p>
              </div>
            </v-col>
            <v-col cols="6" class="mt-4 pt-2 pb-0">
              <div class="d-flex align-center flex-column">
                <progress-speed-chart
                  :value="isSpeedDefined ? speedMobile : '-'"
                  :color="
                    speedMobile == '?'
                      ? '#E5E5F0'
                      : speedMobile >= 90
                      ? 'success'
                      : speedMobile >= 50
                      ? 'warning'
                      : 'error'
                  "
                ></progress-speed-chart>
                <p
                  v-if="isSpeedDefined"
                  class="p-2 text-center mt-3 mb-0 black--text"
                >
                  {{ $t('heading.instance.siteDetails.loadingSpeed.mobile') }}
                </p>
              </div>
            </v-col>
            <h6
              v-if="!isSpeedDefined"
              class="
                p-2
                font-weight-light
                text--heading text-center
                mb-0
                mt-4
                w-100
              "
            >
              {{ $t('heading.instance.siteDetails.loadingSpeed.disabled') }}
              <v-tooltip
                transition="custom-tooltip"
                open-delay="150"
                bottom
                z-index="99"
                offset-overflow
                nudge-bottom="4px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <a v-on="on" v-bind="attrs" class="primary--text" href="#">
                    {{ $t('button.why') }}
                  </a>
                </template>
                <span v-if="$store.state.home.app.page_speed_enabled">
                  {{ $t('tooltip.instance.siteDetails.loadingSpeed.noData.first')}}
                </span>
                <span v-else>
                  {{ $t('tooltip.instance.siteDetails.loadingSpeed.noData.first')}}
                </span>
              </v-tooltip>
            </h6>
          </template>
          <template v-else>
            <v-col class="d-flex align-center justify-center flex-column mt-3">
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.64058 41.1833C-4.88772 33.1559 8.37629 7.73599 27.1787 4.52106C48.2887 0.906751 63.0979 16.642 59.4458 34.7934C55.4124 54.8218 28.8643 64.826 21.2791 57.7172C16.8243 53.5438 24.249 48.4119 18.7708 43.28C13.2926 38.1481 7.63383 44.8974 3.64058 41.1633V41.1833Z"
                  fill="#F9F9FB"
                />
                <path
                  d="M52.0022 38.7721H47.6473M52.0022 38.7721C52.0022 33.0548 49.7708 27.8587 46.1314 24.0071M52.0022 38.7721C52.0022 44.4894 49.7708 49.6855 46.1314 53.5371L43.017 51.3663M8.99805 38.7721H14.1497M8.99805 38.7721C8.99805 33.0548 11.2294 27.8587 14.8688 24.0071M8.99805 38.7721C8.99805 44.4894 11.2294 49.6855 14.8688 53.5371L18.0566 51.3663M30.5001 17.27V21.7724M30.5001 17.27C24.3421 17.27 18.7888 19.8587 14.8688 24.0071M30.5001 17.27C36.6581 17.27 42.2115 19.8587 46.1314 24.0071M14.8688 24.0071L18.0566 26.8369M46.1314 24.0071L43.017 26.8369"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M40.0252 29.2283L28.0882 35.7963L33.4788 41.2982L40.0252 29.2283Z"
                  fill="#EEEEF4"
                  stroke="#77849F"
                  stroke-width="2"
                  stroke-linejoin="round"
                />
                <circle
                  cx="30.0134"
                  cy="39.2914"
                  r="3.99291"
                  transform="rotate(-44.55 30.0134 39.2914)"
                  fill="#EEEEF4"
                  stroke="#77849F"
                  stroke-width="2"
                />
                <path
                  d="M14.8298 17.27C16.2298 17.27 17.3598 16.14 17.3598 14.74C17.3598 13.34 16.2298 12.21 14.8298 12.21C13.4298 12.21 12.2998 13.34 12.2998 14.74C12.2998 16.14 13.4298 17.27 14.8298 17.27Z"
                  stroke="#B0B0D3"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M56.8799 25.7402L52.8799 21.7402"
                  stroke="#77849F"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
                <path
                  d="M52.8799 25.7402L56.8799 21.7402"
                  stroke="#77849F"
                  stroke-width="2"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                />
              </svg>

              <div class="p-4 base--text">
                {{ $t('heading.instance.siteDetails.loadingSpeed.disabled') }}
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <a href="#" v-on="on" v-bind="attrs">{{ $t('button.why') }}</a>
                  </template>

                  <span v-if="$store.state.home.app.page_speed_enabled">
                    {{ $t('tooltip.instance.siteDetails.loadingSpeed.noData.first')}}
                  </span>
                  <span v-else>
                    {{ $t('tooltip.instance.siteDetails.loadingSpeed.noData.first')}}
                  </span>
                </v-tooltip>
              </div>
            </v-col>
          </template>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import ProgressSpeedChart from "../charts/ProgressSpeedChart.vue";

export default {
  components: { ProgressSpeedChart },
  computed: {
    speedDesktop() {
      return this.instance.performance_score.desktop !== null
        ? this.instance.performance_score.desktop * 100
        : null;
    },
    speedMobile() {
      return this.instance.performance_score.mobile !== null
        ? this.instance.performance_score.mobile * 100
        : null;
    },
    isSpeedDefined() {
      return this.speedDesktop !== null && this.speedMobile !== null;
    },
  },
  props: {
    fetching: Boolean,
    instance: Object,
  },
};
</script>

<style lang="scss" scoped>
.full-report-link {
  color: map-get($primary, base);
  .v-icon {
    color: map-get($primary, base);
  }
  transition: color 0.24s ease;
  &:hover {
    color: map-get($primary, lighten1);
    .v-icon {
      color: map-get($primary, lighten1);
    }
  }
}
.row {
  margin: 0 -16px;
}
.v-card__title {
  line-height: $line-height-base;
}
</style>
